import { request } from '../request'

export const voice = {
  // 请求voiceList数据
  getVoiceList: () => {
    return request({
      url: '/voices'
    })
  },
}
