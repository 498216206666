import { request } from "../request";

export const home = {
    // 请求homePage/Top数据
    getHomePageData: () => {
        return request({
            url: "/homePage",
        });
    },

    // 请求homeBanner数据
    getHomeBannerData: () => {
        return request({
            url: "/homeBanner",
        });
    },

     // 请求homeBanner数据
     getAboutPage: () => {
        return request({
            url: "/aboutPage",
        });
    },
};
