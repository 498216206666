/**
 * 将Layout组件放入路由表中，所有页面路由都是子路由
 */
import React, { useState, useEffect, Suspense, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useGoToCaLogin } from "@/custom-hooks";
import useMediaQuery from "use-media-antd-query";

import "@/assets/css/index.less";
import "./index.less";

import Header from "@/components/CaHeader";
import CaMenu from "@/components/CaMenu";
import Footer from "@/components/CaFooter";

import user from "@/data/user";

import { api } from "@/service/api";
import classNames from "classnames";
import { inherits } from "util";
const { auth: authApi } = api;

const HEAD_HIDDEN_ROUTES: string[] = ['/maintenance']
const FOOTER_HIDDEN_ROUTES: string[] = ['/maintenance']
const NOT_RESET_SCROLL_ROUTES: string[] = []; // 路由切换时页面不滚动到顶部的目标路由

const Layout: React.FC = () => {
  const location = useLocation();
  const { goToCaLogin } = useGoToCaLogin();
  const colSize = useMediaQuery();
  const isMobile =
    colSize === "xs" ||
    colSize === "sm" ||
    colSize === "md" ||
    colSize === "lg";

  const [isShow, setIsShow] = useState(false);
  const getMenuStatus = (m: any) => {
    setIsShow(!isShow);
  };
  const isOffMenu = (val: any) => {
    isOffMenuLayout(val);
  };
  const isOffMenuLayout = (val: any) => {
    setIsShow(val);
  };

  // 存在accessToken时检查合法性(只在根组件检查一次)
  const checkAccessToken = () => {
    const accessToken = user.getAccessToken();
    accessToken &&
      authApi
        .checkAccessToken()
        .then((response) => {
          const { code, data } = response;
          // 当accessToken不合法时清除后跳转登录页
          if (1 !== +data?.isLogin) {
            user.clearAccessToken();
            goToCaLogin();
          }
        })
        .catch((error) => {});
  };

  const isHeadHidden = useMemo(() => {
    return !!HEAD_HIDDEN_ROUTES.includes(location.pathname)
  }, [location.pathname])
  const isFooterHidden = useMemo(() => {
    return !!FOOTER_HIDDEN_ROUTES.includes(location.pathname)
  }, [location.pathname])

  // 路由切换时页面滚动到顶部
  useEffect(() => {
    if (!NOT_RESET_SCROLL_ROUTES.includes(location.pathname)) {
      const scrollElement = document.querySelector(".public-layout-11234-main");
      scrollElement && (scrollElement.scrollTop = 0);
    }
  }, [location.pathname]);

  useEffect(() => {
    checkAccessToken();
  }, []);

  return (
    <div className="public-layout-11234-box">
      <CaMenu isOffMenu={isOffMenu} isShow={isShow} />
      {isHeadHidden ? <></> : <Header getMenuStatus={getMenuStatus} />}
      <main
        className={classNames(
          "public-layout-11234-main",
          isMobile ? "mobile" : null
        )}
        style={{paddingTop: isHeadHidden ? 0 : '50px'}}
      >
        <main className="public-layout-11234-content">
          <Suspense>
            <Outlet />
          </Suspense>
        </main>
        {isFooterHidden ? <></> :  <Footer />}
      </main>
    </div>
  );
};

export default Layout;
