import { request } from '../request'

export const contact = {
  // 提交contact数据
  submitContact: (data) => {
    return request({
      method: 'post',
      url: '/contact',
      data
    })
  },
}