export const COMMONLIST = [
  {
    title: "TOP",
    link: "/",
  },
  {
    title: "ABOUT",
    link: "/about",
  },
  {
    title: "EVENT",
    link: "/event",
  },
  {
    title: "JOB OFFER",
    link: "/enterprise",
  },
  {
    title: "STAFF",
    link: "/staff",
  },
  {
    title: "VOICE",
    link: "/voice",
  },
  {
    title: "FAQ",
    link: "/faq",
  },
  {
    title: "CONTACT",
    link: "/contact",
  },
];

export const USERLIST = [
  {
    title: "プロフィール",
    link: "/myPage",
  },
  {
    title: "イベント予約",
    link: "/my-page/event",
  },
  {
    title: "面談予約",
    link: "/my-page/enterprise",
  },
  {
    title: "メッセージ",
    link: "/myPage/message",
  },
  {
    title: "設定",
    link: "/myPage/settings",
  },
];
