import { request } from '../../request'

export const mailSettings = {
    // 获取原邮件
    getUserMail: () => {
        return request({
            url: ''
        })
    },

    // 发送新的邮件验证码
    sendNewMail: (email: string) => {
        return request({
            url: `sendCode?email=${email}`
        })
    },

    // 提交邮件验证码到后台
    sendMailCode: (data) => {
        return request({
            method:"put",
            url: '/resetMail',
            data
        })
    },
}
