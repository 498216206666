import { request } from '../request'

export const footer = {
  // 获取会社数据
  getCompanyMessage: () => {
    return request({
      url: ''
    })
  },
}
