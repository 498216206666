import axios, { ResponseType } from "axios";

import { ObjProp } from "@/config/types";

import user from "@/data/user";

const isProduction = "production" === process.env.NODE_ENV;
const baseUrl = !isProduction ? "" : process.env.REACT_APP_BASE_URL || "";
const path = !isProduction ? "/api" : process.env.REACT_APP_PATH || "";

const LOGIN_STATUS = [401];
const MAINTENANCE_STATUS = [503];

// 单例模式，只允许有一个axios实例，封装对http状态的处理
const axiosIns = (function () {
  return axios.create({
    withCredentials: true,
    timeout: 20000,
    baseURL: `${baseUrl}${path}`,
  });
})();
// 请求拦截
axiosIns.interceptors.request.use(
  (config) => {
    if (user.getAccessToken()) {
      config.headers["Authorization"] = `Bearer ${user.getAccessToken()}`; // NOTICE：这里可能会形成预请求，观察一下
    }
    return config;
  },
  (error) => {
    return Promise.reject(error); // 抛出错误
  }
);
// 响应拦截
axiosIns.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const { response } = error;
    const { status } = response;

    // maintenance mode
    if (MAINTENANCE_STATUS.includes(status)) {
      window.location.href = `${origin}/maintenance`;
    }

    if (LOGIN_STATUS.includes(status)) {
      user.clearAccessToken(); // 清除token
      window.location.href = `${origin}/auth/login`; // 跳转登录页
    }

    const message = "server error";

    if ("string" === typeof response.data) response.data = { message };
    else response.data.message = message;
    return Promise.reject(response.data);
  }
);

// 所有请求都通过request函数发出，封装对业务code状态的处理
export const request = <T>(config: {
  url: string;
  method?: "get" | "post" | "patch" | "put" | "detele";
  params?: ObjProp;
  data?: ObjProp | string;
  responseType?: ResponseType;
  headers?: ObjProp;
}) => {
  const {
    url,
    method = "get",
    params,
    data,
    responseType,
    headers: _headers,
  } = config;
  // POST方法默认Content-Type为application/x-www-form-urlencoded。如果需要传递json，需要手动设置Content-Type
  let headers: ObjProp | undefined = {};
  if (
    ["POST"].includes(config.method?.toUpperCase() as string) &&
    (!_headers || !_headers["Content-Type"])
  ) {
    headers["Content-Type"] = "application/x-www-form-urlencoded";
  } else {
    headers = _headers;
  }
  return axiosIns
    .request<T>({
      url,
      method,
      params,
      data,
      responseType,
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
