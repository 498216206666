import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "@/config/routes";
import "./config/normalize.less";
import "./config/normailzeAntdForm.less";
import reportWebVitals from "./reportWebVitals";
import "./config/i18n/configs";

// Function to store all URL parameters to sessionStorage if utm_source is present
export function storeUtmParameters() {
    const url = new URL(window.location.href);
    
    // Check if 'utm_source' is present in the query parameters
    if (url.searchParams.has('utm_source')) {
        // Get all query parameters
        const allParams = Array.from(url.searchParams.entries())
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&');

        // Store all parameters in session storage
        sessionStorage.setItem('utm_params', allParams);
    }
}

// Main App component
const App = () => {
    React.useEffect(() => {
        storeUtmParameters();
    }, []); // Empty dependency array means it runs once on mount

    return null; // This can be modified to return more UI as needed
};

// Create the router
const router = createBrowserRouter(routes);

// Set lodash to the global window object
window._ = require("lodash");

// Render the application
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <App /> {/* Include the App component here */}
        <RouterProvider router={router} />
    </React.StrictMode>
);

reportWebVitals();
