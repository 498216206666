import { request } from '../request'

export const staff = {
  // 请求staffList数据
  getStaffList: () => {
    return request({
      url: '/staff'
    })
  },
}
