import { ObjProp } from '@/config/types'

// TODO: 用户信息应该整体持久化
class User {
  private _accessToken?: string // 用户登录凭证

  constructor () {
    this.initialAccessToken()
  }

  // 从本地读取accessToken并初始化
  initialAccessToken () {
    this.setAccessToken(
      window.localStorage.getItem('accessToken'),
      false
    )
  }

  getAccessToken () {
    return this._accessToken
  }

  setAccessToken (accessToken, store = true) {
    this._accessToken = accessToken
    store && this.storeAccessToken()
  }

  // 持久化accessToken
  storeAccessToken () {
    window.localStorage.setItem('accessToken', this.getAccessToken() || '')
  }

  // 清除本地accessToken
  clearAccessToken () {
    this.setAccessToken('')
  }

  // 设置用户信息
  setInfo (info: ObjProp) {}

  // 重置用户信息
  clearInfo () {
    this._accessToken = undefined
  }

  // 判断用户是否登录
  isLogin () {
    return !!this.getAccessToken()
  }
}
export default new User()