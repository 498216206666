import { request } from '../request'

export const faq = {
  // 请求faqCategories数据
  getFaqList: () => {
    return request({
      url: '/faq'
    })
  },
}
