import CameraSvg from "../../public/imgs/camera.svg";
import TikTokSvg from "../../public/imgs/tiktok.svg";
import LineSvg from "../../public/imgs/line.svg";
import HouseSvg from "../../public/imgs/house.svg";
import PhoneSvg from "../../public/imgs/phone.svg";
import React, { useEffect, useState } from "react";
import "./index.less";
import classNames from "classnames";
import useMediaQuery from "use-media-antd-query";
import { CaComponentProps } from "@/config/types";
import { api } from "@/service/api";
import {
    CompanyLink,
    InstgramLink,
    LineLink,
    PrivacyPolicyLink,
    TikTokLink,
} from "@/data/constants";
import moment from "moment";
const { footer } = api;
type CaFooterProps = CaComponentProps & {};

const data = {
    companyTime: `Copyright ©${moment().format(
        "YYYY"
    )} 女子キャリ All right reserved.`,
    companyPhone: "03-6803-8638",
    companyAddress: "東京都渋谷区渋谷2丁目21-1 渋谷ヒカリエ32F",
};

const CaFooter: React.FC<CaFooterProps> = ({}) => {
    const colSize = useMediaQuery();
    const isMobile = colSize === "xs";
    const [CompanyMessage, getCompanyMessage] = useState(data);
    const openCompanyLink = () => {
        window.open(CompanyLink, "_blank");
    };
    const openPrivacyPolicyLink = () => {
        window.open(PrivacyPolicyLink, "_blank");
    };
    const openLine = () => {
        window.open(LineLink, "_blank");
    };
    const openTikTok = () => {
        window.open(TikTokLink, "_blank");
    };
    const openInstagram = () => {
        window.open(InstgramLink, "_blank");
    };
    const toPath = () => {
        window.open("/contact", "_self");
    };
    const softLink = (link) => {};
    useEffect(() => {
        // 获取商标信息等
        // footer.getCompanyMessage().then()
        // getCompanyMessage()
    }, []);
    const toTel = () => {
        if (isMobile) {
            window.location.href = `tel:${CompanyMessage.companyPhone}`;
        }
    };
    return (
        <footer className="ca-footer-layout">
            <main
                className={classNames(
                    isMobile ? "ca-footer-layout-phone" : "ca-footer-layout-pc"
                )}
            >
                <ul className="public-layout-11234-work-footer-svg">
                    <li onClick={openInstagram}>
                        <CameraSvg />
                    </li>
                    <li onClick={openTikTok}>
                        <TikTokSvg />
                    </li>
                    <li onClick={openLine}>
                        <LineSvg />
                    </li>
                </ul>
                <ul className="ca-footer-layout-word-message">
                    <li>
                        <HouseSvg />
                        <p>{CompanyMessage.companyAddress}</p>
                    </li>
                    <li onClick={toTel}>
                        <PhoneSvg />
                        <p>{CompanyMessage.companyPhone}</p>
                    </li>
                </ul>
                <ul className="public-layout-11234-work-footer-corporation">
                    <li>
                        <p>
                            <span onClick={openCompanyLink}>運営会社</span>｜
                            <span onClick={openPrivacyPolicyLink}>
                                プライバシーポリシー
                            </span>
                            ｜<span onClick={toPath}>お問い合わせ</span>
                        </p>
                    </li>
                    <li>
                        <p>{CompanyMessage.companyTime}</p>
                    </li>
                </ul>
            </main>
        </footer>
    );
};

export default CaFooter;
