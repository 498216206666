import { request } from "../request";

export const event = {
  // 请求eventList数据
  getEventListData: () => {
    return request({
      url: "/eventList",
    });
  },

  // 请求eventDetail数据
  getEventDetailData: (eventId: number | string) => {
    return request({
      url: `/eventDetail/${eventId}`,
    });
  },

  // 预约event
  eventAppoint: (data: {
    schedule_id: number | string;
    reservation_date: string;
    is_waiting_cancel?: number | string;
  }) => {
    return request({
      method: "post",
      url: `/eventReservation`,
      data,
    });
  },

  // 请求myEventList数据
  getMyEventList: () => {
    return request({
      url: "/my/events",
    });
  },

  // 请求myEventDetail数据
  getMyEventDetail: (eventId: number | string) => {
    return request({
      url: `/my/events/${eventId}`,
    });
  },

  // 取消预约event
  eventAppointCanel: (eventId: number | string) => {
    return request({
      method: "put",
      url: `/my/events/${eventId}/cancel`,
    });
  },

  // 请求recommendEventList数据
  getRecommendEventListData: () => {
    return request({
      url: "/getRecommended",
    });
  },

  // 请求enterpriseList数据
  getEnterpriseListData: () => {
    return request({
      url: "/enterpriseList",
    });
  },

  // 请求enterpriseDetail数据
  getEnterpriseDetailData: (enterpriseId: number | string) => {
    return request({
      url: `/enterpriseDetail/${enterpriseId}`,
    });
  },

  // 请求enterpriseDetail中的信息看板数据
  getEnterpriseDetailBoardData: () => {
    return request({
      url: "/getEnterpriseCommon",
    });
  },

  // 请求可以预约的日期时间
  getEnterpriseAppointDateTime: () => {
    return request({
      url: "/getDatesInRange",
    });
  },

  // 预约enterprise
  enterpriseAppoint: (data: {
    enterprise_id: number | string;
    reservation_date: string;
    reservation_time_id: number | string;
  }) => {
    return request({
      method: "post",
      url: "/interviewReservation",
      data,
    });
  },

  // 请求myEnterprise数据
  getMyEnterpriseList: () => {
    return request({
      url: "/interviewList",
    });
  },

  // 请求myEnterpriseDetail数据
  getMyEnterpriseDetail: (enterpriseId: number | string) => {
    return request({
      url: `/interviewDetail/${enterpriseId}`,
    });
  },

  // 取消预约enterprise
  enterpriseAppointCanel: (enterpriseId: number | string) => {
    return request({
      method: "patch",
      url: `/interviewCancel/${enterpriseId}`,
    });
  },

  // 请求recommendenterpriseList数据
  getRecommendEnterpriseListData: () => {
    return request({
      url: "/interviewRecommended",
    });
  },
};
