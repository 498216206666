import { lazy } from "react";

import Layout from "../pages/layout";
import routes_l from "./routes_l";
import LineRegisterCushion from "@/pages/auth/CaLogin/CaLineRegisterCushion";

const Login = lazy(() => import("../pages/auth/CaLogin"));
const PasswordForgot = lazy(() => import("../pages/auth/CaPasswordForgot"));
const PersonalInformation = lazy(
  () => import("../pages/auth/CaPersonalInformation")
);
const PasswordReset = lazy(() => import("../pages/auth/CaPasswordReset"));
const NewLogon = lazy(() => import("../pages/auth/CaLogon/CaLogonNew"));
const CaHome = lazy(() => import("../pages/CaHome/CaHome"));
const CaMyPageProFile = lazy(() => import("../pages/CaMyPage/CaProFile"));
const CaMyPageStopMessage = lazy(
  () => import("../pages/CaMyPage/CaMyPageStopMessage")
);
const CaLogonMessage = lazy(
  () => import("../pages/auth/CaLogon/CaLogonMessage")
);
const CaLogonSuccess = lazy(
  () => import("../pages/auth/CaLogon/CaLogonSuccess")
);
const CaMyPageSettings = lazy(
  () => import("../pages/CaMyPage/CaMyPageSettings")
);
const CaMyPageWithdrawal = lazy(
  () => import("../pages/CaMyPage/CaMyPageWithdrawal")
);
const CaMyPagePasswordSetting = lazy(
  () => import("../pages/CaMyPage/CaMyPagePasswordSetting")
);
const CaMyPageMailSetting = lazy(
  () => import("../pages/CaMyPage/CaMyPageMailSetting")
);
const CaMyPageMailCode = lazy(
  () => import("../pages/CaMyPage/CaMyPageMailCode")
);
const CaMyPageOpenMessage = lazy(
  () => import("../pages/CaMyPage/CaMyPageOpenMessage")
);
const CaMyPageMessage = lazy(() => import("../pages/CaMyPage/CaPageMessage"));
const CaHomeAbout = lazy(() => import("../pages/CaHome/CaHomeAbout"));
const CaMyPageUserMessage = lazy(
  () => import("../pages/CaMyPage/CaMyPageIndex")
);
const LineCallback = lazy(() => import("../pages/auth/CaLogin/CaLineCallback"));
const LineRegister = lazy(() => import("../pages/auth/CaLogin/CaLineRegister"));

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <CaHome />,
      },
      {
        path: "/home",
        element: <CaHome />,
      },
      {
        path: "/about",
        element: <CaHomeAbout />,
      },
      {
        path: "/auth/login",
        element: <Login />,
      },
      {
        path: "/auth/login/callback",
        element: <LineCallback />,
      },
      {
        path: "/auth/login/lineregister",
        element: <LineRegister />,
      },
      {
        path: "/auth/lineRegisterSuccess",
        element: <CaLogonSuccess />,
      },
      {
        path: "/auth/passwordForgot",
        element: <PasswordForgot />,
      },
      {
        path: "/auth/passwordReset",
        element: <PasswordReset />,
      },
      {
        path: "/auth/PersonalInformation",
        element: <PersonalInformation />,
      },
      {
        path: "/lineRegisterCushion",
        element: <LineRegisterCushion />,
      },
      {
        path: "/register",
        element: <NewLogon />,
      },
      {
        path: "/auth/register",
        element: <CaLogonMessage />,
      },
      {
        path: "/auth/registerSuccess",
        element: <CaLogonSuccess />,
      },
      {
        path: "/myPage",
        element: <CaMyPageUserMessage />,
      },
      {
        path: "/myPage/settings",
        element: <CaMyPageSettings />,
      },
      {
        path: "/myPage/withdrawal",
        element: <CaMyPageWithdrawal />,
      },
      {
        path: "/myPage/profile",
        element: <CaMyPageProFile />,
      },
      {
        path: "/myPage/passwordSetting",
        element: <CaMyPagePasswordSetting />,
      },
      {
        path: "/myPage/mailSetting",
        element: <CaMyPageMailSetting />,
      },
      {
        path: "/MyPage/mailCode",
        element: <CaMyPageMailCode />,
      },
      {
        path: "/MyPage/openMessage",
        element: <CaMyPageOpenMessage />,
      },
      {
        path: "/myPage/stopMessage",
        element: <CaMyPageStopMessage />,
      },
      {
        path: "/myPage/message",
        element: <CaMyPageMessage />,
      },
      ...routes_l,
    ],
  },
];

export default routes;
