import { auth } from './auth'
import { myPage } from './my-page'
import { event } from './event'
import { faq } from './faq'
import { staff } from './staff'
import { voice } from './voice'
import { contact } from './contact'
import { logonMail } from "./logonMail";
import { home } from "./home"
import { footer } from './caFooter'

export const api = {
  auth,
  myPage,
  event,
  faq,
  staff,
  voice,
  contact,
  logonMail,
  home,
  footer
}
