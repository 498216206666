import { lazy } from "react"

import { Navigate } from "react-router-dom"

const CaLogonDone = lazy(() => import("../pages/auth/CaLogon/CaLogonDone"))
const CaEvent = lazy(() => import("../pages/CaEvent"))
const CaEventDetail = lazy(() => import("../pages/CaEventDetail"))
const CaEnterpriseAppoint = lazy(() => import("../pages/CaEnterpriseAppoint"))
const CaVoice = lazy(() => import("../pages/CaVoice"))
const CaFaq = lazy(() => import("../pages/CaFaq"))
const CaFaqDetail = lazy(() => import("../pages/CaFaq/CaFaqDetail"))
const CaContact = lazy(() => import("../pages/CaContact"))
const CaStaff = lazy(() => import("../pages/CaStaff"))
const CaMyPageEvent = lazy(() => import("../pages/CaMyPage/CaMyPageEvent"))
const CaMyPageEventDetail = lazy(() =>
  import("../pages/CaMyPage/CaMyPageEventDetail")
)
const CaUnsubscribe = lazy(() => import("../pages/CaUnsubscribe"))
const CaUnsubscribeAbout = lazy(() => import("../pages/CaUnsubscribeAbout"))
const Ca404 = lazy(() => import("../pages/Ca404"))
const Ca503 = lazy(() => import("../pages/Ca503"))

const routes_l = [
  {
    path: "/auth/logon/done",
    element: <CaLogonDone />,
  },
  {
    path: "/event",
    element: <CaEvent />,
    meta: {
      a: 1,
    },
  },
  {
    path: "/event/:eventId",
    element: <CaEventDetail />,
  },
  {
    path: "/enterprise",
    element: <CaEvent />,
  },
  {
    path: "/enterprise/:enterpriseId",
    element: <CaEventDetail />,
  },
  {
    path: "/enterprise-appoint",
    element: <CaEnterpriseAppoint />,
  },
  {
    path: "/enterprise-appoint/:enterpriseId",
    element: <CaEnterpriseAppoint />,
  },
  {
    path: "/enterprise-appoint/change/:enterpriseId",
    element: <CaEnterpriseAppoint />,
  },
  {
    path: "/voice",
    element: <CaVoice />,
  },
  {
    path: "/faq",
    element: <CaFaq />,
    children: [
      {
        path: "/faq/:faqId",
        element: <CaFaqDetail />,
      },
    ],
  },
  {
    path: "/contact",
    element: <CaContact />,
  },
  {
    path: "/staff",
    element: <CaStaff />,
  },
  {
    path: "/my-page/event",
    element: <CaMyPageEvent />,
  },
  {
    path: "/my-page/event/:id",
    element: <CaMyPageEventDetail />,
  },
  {
    path: "/my-page/enterprise",
    element: <CaMyPageEvent />,
  },
  {
    path: "/my-page/enterprise/:id",
    element: <CaMyPageEventDetail />,
  },
  {
    path: "/unsubscribe",
    element: <CaUnsubscribe />,
  },
  {
    path: "/unsubscribeAbout",
    element: <CaUnsubscribeAbout />,
  },
  {
    path: "/404",
    element: <Ca404 />,
  },
  {
    path: "/maintenance",
    element: <Ca503 />,
  },
  {
    path: "*",
    element: <Navigate to="/404" />,
  },
]

export default routes_l
