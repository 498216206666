import React from 'react'
import classNames from 'classnames'
import {Button, ButtonProps} from 'antd'
import { ButtonHTMLType } from 'antd/es/button/buttonHelpers'

import './index.less'

import TriangleTheme from '@/assets/images/triangle-theme.svg'
import TriangleWhite from '@/assets/images/triangle-white.svg'

interface CaButtonProps extends ButtonProps {
  className?: string,
  type?: 'default' | 'primary',
  size?: 'small' | 'middle' | 'large', // 注意值modal为模态框按钮特殊尺寸
  inModal?: boolean,
  icon?: string | React.ReactNode, // icon需要传入ReactNode
  iconPosition?: 'left' | 'right',
  ghost?: boolean, // 开启ghost模式后按钮背景透明且有边框
  loading?: boolean,
  disabled?: boolean,
  clickHandle?: () => void,
  children?: React.ReactNode,
  htmlType?: ButtonHTMLType
}

const getIcon = (type: CaButtonProps['type'], ghost: CaButtonProps['ghost']) => {
  return 'default' === type ?
         (!ghost ? <TriangleTheme /> : <TriangleWhite />) :
         (!ghost ? <TriangleWhite /> : <TriangleTheme />)
}

const CaButton: React.FC<CaButtonProps> = (props) => {
  let {
    className,
    htmlType,
    type = 'default',
    size = 'middle',
    inModal,
    icon,
    iconPosition = 'right',
    ghost,
    loading,
    disabled,
    clickHandle,
    onClick,
    children
  } = props
  !icon && (icon = getIcon(type, ghost))

  return (
    <div className={classNames('CaButton', className)}>
      <Button
        className={classNames(
          icon && `icon-position-${iconPosition}`,
          inModal && 'CaButton-modal'
        )}
        type={type}
        // @ts-ignore
        size={['small', 'middle', 'large'].includes(size) ? size : undefined}
        ghost={!!ghost}
        loading={!!loading}
        disabled={!!disabled}
        onClick={e => {clickHandle?.(); onClick?.(e)}}
        htmlType={htmlType}
      >
        <span>{children}</span>
        {icon && <div className={`CaButton-icon `}>{icon}</div>}
      </Button>
    </div>
  )
}

export default CaButton
