import { request } from '../../request'

export const myMessage = {

    // メッセージ一覧 group： 1.inbox　受信BOX 2.important　重要なお知らせ 3. recycled　ゴミ箱  q： 検索
    getMessageList: (group: string,q: string) => {
        return request({
            url: `/my/message/${group}?q=${q}`
        })
    },

    // メッセージ一更新/詳細   action:  1.opened 開封フラグ(查看更新状态)  2.recycled 削除  ids：1,2,3 OR 1
    messageAction: (action:string,ids: string) => {
        return request({
            method:"put",
            url: `/my/message/${action}/${ids}`,
        })
    },
}
